<template>
  <div
    :key="componentKey"
    v-if="(valido && $dataUser[0].idRol == 3) || $dataUser[0].idRol == 8 || $dataUser[0].idRol == 7"
  >
    <FullBoxVue class="shadowHover">
      <h1>Usuario {{ infoSolicitud.nombre }}</h1>
    </FullBoxVue>
    <FullBoxVue class="shadowHover">
      <v-form>
        <p>
          Estado actual usuario:
          <span
            :class="
              infoSolicitud.estado == 'Habilitado'
                ? 'primary--text'
                : 'error--text'
            "
            >{{ infoSolicitud.estado }}</span
          >
        </p>
        <v-btn
          :color="infoSolicitud.estado == 'Habilitado' ? 'error' : 'primary'"
          @click="habilitarUsuario()"
        >
          {{
            infoSolicitud.estado == "Habilitado" ? "deshabilitar" : "Habilitar"
          }}
          usuario
        </v-btn>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoSolicitud.cod_cliente"
                label="Código de cliente"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoSolicitud.nombre"
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoSolicitud.correo"
                label="Correo"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoSolicitud.contacto"
                label="Contacto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="infoSolicitud.infoEmpresa">
              <v-text-field
                v-if="infoSolicitud.rol == 8 || infoSolicitud.rol == 2"
                v-model="infoSolicitud.infoEmpresa.razonsocial"
                label="Empresa"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoSolicitud.tipo_documento"
                label="Tipo de documento"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoSolicitud.documento"
                label="Número de documento"
              ></v-text-field>
            </v-col>

            <v-select
              v-if="
                infoSolicitud.rol == '7' ||
                infoSolicitud.rol == '5' ||
                infoSolicitud.rol == '6'
              "
              v-model="infoSolicitud.serviciosAsociados"
              :items="serviciosDefault"
              item-text="nombre"
              item-value="id"
              label="Servicios disponibles"
              multiple
            ></v-select>

            <div v-if="infoSolicitud.rol == '6'">
              <v-text-field
                v-model="infoSolicitud.cuenta"
                label="Número de cuenta Banco"
                style="
                  width: 31.333%;
                  max-width: 31.333%;
                  margin: 1%;
                  float: left;
                "
              ></v-text-field>
              <v-text-field
                v-model="infoSolicitud.banco"
                label="Banco"
                style="
                  width: 31.333%;
                  max-width: 31.333%;
                  margin: 1%;
                  float: left;
                "
              ></v-text-field>
              <v-text-field
                v-model="infoSolicitud.tipoCuenta"
                label="Tipo de cuenta"
                style="
                  width: 31.333%;
                  max-width: 31.333%;
                  margin: 1%;
                  float: left;
                "
              ></v-text-field>
              <v-text-field
                v-model="infoSolicitud.ciudad"
                label="Ciudad"
                style="
                  width: 31.333%;
                  max-width: 31.333%;
                  margin: 1%;
                  float: left;
                "
              ></v-text-field>
              <v-text-field
                v-model="infoSolicitud.direccion"
                label="Dirección"
                style="
                  width: 31.333%;
                  max-width: 31.333%;
                  margin: 1%;
                  float: left;
                "
              ></v-text-field>
            </div>

            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="infoSolicitud.fechaCorte"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="infoSolicitud.fechaCorte"
                  chips
                  small-chips
                  label="Fecha de corte"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="infoSolicitud.fechaCorte"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->
          </v-row>
        </v-container>
        <v-btn color="primary" @click="actualizarUsuario"> Actualizar </v-btn>
        <br>
        <br>
        <v-btn color="primary" @click="actualizarPassword"> Cambiar contraseña y enviar al correo </v-btn>
      </v-form>
    </FullBoxVue>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import MiddleBox from "@/components/static/MiddleBox.vue";
import searchUserMultipleVue from "../components/searchUserMultiple.vue";

import {
  getSpecificUser,
  updateSpecificUser,
  getServicio,
  getClientes,
  habilitarUsuario,
} from "@/api";

export default {
  data() {
    return {
      infoSolicitud: {
        cuenta: "",
        banco: "",
        tipoCuenta: "",
        ciudad: "",
        direccion: "",
      },
      infoSolicitudCandidatos: {},
      componentKey: 1,
      estados: null,
      idSolicitud: this.$route.params.id,
      archivosSubidos: [],
      Images: null,
      valido: false,
      userSearchSelected: [],
      userSearch: [],
      menu: false,
      serviciosDefault: [],
    };
  },

  components: {
    FullBoxVue,
    MiddleBox,
    searchUserMultipleVue,
  },

  methods: {
    userSearchSelectedFunc(values) {
      this.infoSolicitud.userSearchSelected = values;
    },
    realizarConsulta() {
      getSpecificUser(this.idSolicitud).then(
        function (response) {
          // if((response.data[0].rol != 8 || response.data[0].rol != 2) && (this.$dataUser[0].idRol == 8 || this.$dataUser[0].empresa == response.data[0].empresa)){
          this.infoSolicitud = response.data[0];

          this.componentKey += 1;
        }.bind(this)
      );
    },
    actualizarPassword() {
      changePassword({
        user: this.infoSolicitud.correo,
      }).then(
        function (response) {
          if (response.data[0].status == 1) {
            this.msgAlert = response.data[0].msg;
            this.alert = true;
          } else {
            this.msgError = response.data[0].msg;
            this.alertError = true;
          }
        }.bind(this)
      );
    },
    actualizarUsuario() {
      updateSpecificUser(this.infoSolicitud).then(
        function (response) {}.bind(this)
      );
    },
    consultaServicio() {
      getServicio().then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDefault.push({
              id: this.preServicios[i].ID_SERVICIO,
              nombre: this.preServicios[i].NOMBRE_SERVICIO,
            });
          }
        }.bind(this)
      );
    },
    habilitarUsuario() {
      habilitarUsuario({ idUsuario: this.infoSolicitud.cod_cliente }).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },
  },

  mounted() {
    getClientes().then(
      function (response) {
        this.userSearch = response.data;
      }.bind(this)
    );
    this.realizarConsulta();
    this.consultaServicio();
  },

  watch: {
    infoSolicitud(newState) {
      if (newState != {}) {
        this.valido = true;
      }
    },
  },
};
</script>

<style scoped>
.archivo {
  min-width: 150px;
  min-height: 170px;
}

.archivo:hover {
  background: #385da6;
  color: #fff;
}

.archivo:hover > div > i {
  color: #fff;
}
.archivo:hover > div {
  cursor: pointer;
}

v-text-field {
  width: 50%;
}
</style>
